import { z } from "zod";
import { CDSIdSchema } from "@/api/protein_search/schemas";
export const AlignmentRequestSchema = z.object({
  query: z.string(),
  match_list: z.array(
    z.object({
      cdsId: CDSIdSchema,
      sequence: z.string(),
    }),
  ),
});

export const AlignmentRecordSchema = z.object({
  query: z.string(),
  refCdsId: CDSIdSchema,
  ref: z.string(),
  midline: z.string(),
  query_coverage: z.number(),

  percent_identity: z.number(),
});
export type AlignmentRecord = z.infer<typeof AlignmentRecordSchema>;
export const AlignmentResponseSchema = z.object({
  query: z.string(),
  records: z.array(AlignmentRecordSchema),
});

export type AlignmentResponse = z.infer<typeof AlignmentResponseSchema>;
