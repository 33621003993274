import {
  AuthContextType,
  LoginRequest,
  LoginResponse,
  LoginResponseSchema,
  SignupRequest,
  SignupResponseSchema,
  User,
} from "@/api/auth/schemas";
import { useStickyState } from "@/hooks/useStickyState";
import { gaiaAuth } from "@/utils/wretch";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import posthog from "posthog-js";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useStickyState<User | null>({
    key: "user",
    prefix: "gaia",
    version: "0",
    defaultValue: null,
  });
  useEffect(
    function identifyUser() {
      Sentry.setUser({ email: user?.emailAddress });
      posthog.identify(user?.emailAddress);
    },
    [user],
  );
  const [token, setToken] = useStickyState<string | null>({
    key: "token",
    prefix: "gaia",
    version: "0",
    defaultValue: null,
  });
  const navigate = useNavigate();

  const login = async (data: LoginRequest): Promise<LoginResponse | null> => {
    let unAuthorized = false;
    const raw = await gaiaAuth
      .url("/token")
      .post({ ...data })
      .unauthorized(() => {
        unAuthorized = true;
      })
      .json();
    if (unAuthorized) {
      console.error("Failed to log in. Unauthorized.");
      return null;
    }
    const parsed = LoginResponseSchema.safeParse(raw);
    if (parsed.success) {
      setUser(parsed.data.user);
      setToken(parsed.data.access_token);
      return parsed.data;
    } else {
      console.error("Internal error, failed to parse login response.");
      return null;
    }
  };
  const signup = async (data: SignupRequest) => {
    const raw = await gaiaAuth.url("/request_account").post(data).json();
    const parsed = SignupResponseSchema.parse(raw);
    return parsed;
  };
  const logout = () => {
    posthog.reset();
    setUser(null);
    setToken(null);
    navigate("/", { replace: true });
  };

  const value: AuthContextType = useMemo(
    () => ({
      user,
      token,
      login,
      logout,
      signup,
    }),
    [user], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
  );
};
