import { useFolding } from "@/api/folding/hooks";
import { HMMAnnotation, getColorForHMM } from "@/api/hmm_annotate/schemas";
import { Button } from "@/components/ui/button/button";
import { cn } from "@/utils/strings";
import { MoleculeViewer } from "@nitro-bio/molstar-easy";
import { MoleculePayload } from "@nitro-bio/molstar-easy/dist/MoleculeViewer";
import { InfoIcon, LoaderCircleIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

export const FoldingCard = ({
  protein_seq,
  structureHexColor,
  className,
  annotations,
  isFetchingHMMAnnotate,
  hmmAnnotateError,
  currentAnnotationIdx,
  setCurrentAnnotationIdx,
}: {
  protein_seq: string;
  structureHexColor?: string;
  className?: string;
  currentAnnotationIdx: number | null;
  setCurrentAnnotationIdx: (idx: number | null) => void;
  annotations: HMMAnnotation[];
  isFetchingHMMAnnotate: boolean;
  hmmAnnotateError: Error | null;
}) => {
  const { foldingData, isFetchingFolding, foldingError } = useFolding({
    protein_seq,
  });
  const highlights =
    annotations?.map((ann) => {
      return {
        label: {
          text: ann.label,
          hexColor: getColorForHMM(ann),
          scale: 0, // hide label in the viewer
        },
        start: ann.start,
        end: ann.end,
        pfam_id: ann.pfamId,
      };
    }) ?? [];
  let payloads: MoleculePayload[] = [];
  if (foldingData) {
    const currentHighlight =
      currentAnnotationIdx !== null ? highlights[currentAnnotationIdx] : null;
    payloads = [
      {
        pdbString: foldingData.protein_structure,
        highlights: currentHighlight ? [currentHighlight] : [],
        structureHexColor,
      },
    ];
  }
  return (
    <div
      className={cn(
        "overflow-hidden rounded-md border px-4 py-2",
        "bg-molstar",
        className,
      )}
    >
      <div className={cn("relative flex h-full flex-col")}>
        {isFetchingFolding && (
          <p className="flex animate-pulse gap-1 text-xs text-brand-600">
            <LoaderCircleIcon className="my-auto h-4 w-4 animate-spin text-brand-400" />
          </p>
        )}
        {foldingError && (
          <p className="text-xs text-red-500">Error folding protein sequence</p>
        )}

        <div className="absolute right-0 top-0 z-[10] flex h-12 w-fit gap-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="xs" variant="ghost">
                <InfoIcon className="h-3 w-3 text-noir-600" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p>Predicted using ESMFold</p>
            </TooltipContent>
          </Tooltip>

          <Button
            size="xs"
            onClick={() => setCurrentAnnotationIdx(null)}
            variant={currentAnnotationIdx === null ? "outline" : "default"}
            disabled={currentAnnotationIdx === null}
          >
            Clear
          </Button>
        </div>
        <MoleculeViewer
          moleculePayloads={payloads}
          className="min-h-80 w-full flex-1"
          backgroundHexColor="#FCFBFA"
        />
        <p className="flex gap-1 text-noir-800">
          {isFetchingHMMAnnotate ? (
            <LoaderCircleIcon className="my-auto h-4 w-4 animate-spin text-noir-400" />
          ) : (
            <>{highlights.length} HMMs</>
          )}
        </p>
        <div className="flex flex-col overflow-y-auto bg-white">
          {hmmAnnotateError && (
            <p className="text-red-600">Failed to load HMMs</p>
          )}

          {highlights.map((highlight, i) => (
            <div className={cn("form-control h-12")} key={i}>
              <label
                className="label cursor-pointer p-0 pr-4"
                style={{
                  backgroundColor: highlight.label.hexColor,
                }}
              >
                <span
                  className={cn(
                    "rounded-r-full bg-white/40 py-1 pl-2 text-black/80",
                    highlight.pfam_id ? "pr-2" : "pr-4",
                  )}
                >
                  {highlight.pfam_id ? (
                    <Tooltip>
                      <TooltipTrigger>
                        <Button size="xs" variant="ghost" asChild>
                          <Link
                            to={`https://www.ebi.ac.uk/interpro/entry/pfam/${highlight.pfam_id}`}
                            target="_blank"
                            className="!text-md !bg-inherit hover:underline"
                          >
                            <p>{highlight.label.text}</p>
                          </Link>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View on InterPro</p>
                      </TooltipContent>
                    </Tooltip>
                  ) : (
                    highlight.label.text
                  )}
                </span>
                <Button
                  size="xs"
                  variant="ghost"
                  className="!text-white hover:!text-black"
                  disabled={currentAnnotationIdx === i}
                  onClick={() => setCurrentAnnotationIdx(i)}
                >
                  View
                </Button>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
