import { useAuth } from "@/api/auth/hooks";
import { Button } from "@/components/ui/button/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

// Define the schema for form validation
const loginSchema = z.object({
  emailAddress: z.string().email("Invalid email address"),
});
type LoginPayload = z.infer<typeof loginSchema>;

export const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      emailAddress: "",
    },
  });

  const onSubmit = async (data: LoginPayload) => {
    const res = await login(data);
    if (res) {
      navigate("/");
    } else {
      alert(
        "Failed to log in. If you haven't created an account, please sign up.",
      );
    }
  };

  return (
    <main className="mx-auto flex min-h-screen flex-col items-center justify-center gap-6 bg-noir-200">
      <header className="flex flex-col items-center gap-6">
        <img src="/tatta_logo.svg" className="-mb-8 h-24" />
        <h2 className="flex gap-1 text-xl font-bold">
          Log in to <p className="tracking-wide text-brand-600">Gaia</p>
        </h2>
        <p className="mx-auto max-w-md px-2 text-left text-center text-xs text-noir-600">
          Gaia is an embedding-based search engine for sequences. We are
          currently in beta testing, sign up to test new features and help us
          improve Gaia.
        </p>
      </header>

      <form
        className="flex w-80 flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex items-center  justify-center ">
          {`Don't have an account?`}{" "}
          <Button variant="link" asChild size="sm" className="px-0">
            <Link to="/signup">Sign up</Link>
          </Button>
        </p>
        <label className="flex flex-col gap-2 text-xs">
          <h4 className="font-semibold">Email</h4>
          <Input type="email" {...register("emailAddress")} />
          {errors.emailAddress && (
            <span className="text-red-500">{errors.emailAddress.message}</span>
          )}
        </label>
        <Button type="submit">Log in</Button>
      </form>
    </main>
  );
};
