import { LoginPage } from "@/pages/Auth/LoginPage";
import SignupPage from "@/pages/Auth/SignupPage";
import { FourOhFour } from "@/pages/FourOhFour/FourOhFour";
import { HomePage } from "@/pages/HomePage/HomePage";
import { SearchPage } from "@/pages/SearchPage/SearchPage";
import { ProtectedRoute } from "@/providers/auth/ProtectedRoute";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { useAnalyticsEvent } from "@/utils/analytics";
import { useEffect } from "react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: React.FC = () => {
  const { trackCustomEvent } = useAnalyticsEvent();
  useEffect(function trackAppLoaded() {
    trackCustomEvent({
      eventName: "loaded",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route
        path="/search/:sequence"
        element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      // catch-all route
      <Route path="*" element={<FourOhFour />} />
    </SentryRoutes>
  );
};

export default App;
