import { useAlignment } from "@/api/alignment/hooks";
import {
  CDSId,
  ProteinSearchResponseOutput,
  UNANNOTATED_CLIP_LABEL,
} from "@/api/protein_search/schemas";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button/button";
import { InfoIcon } from "lucide-react";
import { GenomicContextViz } from "../GenomicContextViz/GenomicContextViz";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { ActiveCard } from "./ActiveCard";
import { usePaginator } from "@/hooks/usePaginator";
import { cn } from "@/utils/strings";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import DownloadButtons from "../DownloadButtons";
import { taxonomyToLinageString } from "@/api/protein_search/utils";

export const PaginatedAccordion = ({
  proteinSearchResults,
}: {
  proteinSearchResults: ProteinSearchResponseOutput;
}) => {
  const {
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    currentDataSlice: currentMatchData,
  } = usePaginator({
    data: proteinSearchResults.searchResults,
    resultsPerPage: 10,
  });
  const { alignmentData, isFetchingAlignment, alignmentError } = useAlignment({
    query: proteinSearchResults.query.record.sequence,
    match_list: proteinSearchResults?.searchResults?.map(
      (record) => record.match,
    ),
  });

  const findAlignmentByCDSId = (cdsId: CDSId) => {
    const res =
      alignmentData?.records.find((result) => {
        return result.refCdsId.cds_shorthand === cdsId.cds_shorthand;
      }) ?? null;
    return res;
  };
  const contextLengths = proteinSearchResults.searchResults.map(
    (searchResult) => {
      const minStart = Math.min(
        ...[searchResult.match, ...searchResult.contig].map(
          (x) => x.cdsId.start,
        ),
      );
      const maxEnd = Math.max(
        ...[searchResult.match, ...searchResult.contig].map((x) => x.cdsId.end),
      );
      return maxEnd - minStart;
    },
  );
  const maxContextLength = Math.max(...contextLengths);

  return (
    <Accordion type="multiple" className="flex flex-col justify-start gap-4 ">
      <header className="flex flex-wrap items-end justify-between  gap-x-8 gap-y-2 ">
        <span className="flex flex-col gap-1">
          <h4 className="font-semibold">
            {proteinSearchResults.searchResults.length} Search result
            {proteinSearchResults.searchResults.length > 1 ? "s" : ""}
          </h4>
          <span className="flex items-center ">
            <p className="mr-8 text-xs">
              Page {currentPage} of {totalPages}
            </p>

            <Button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="mr-2"
              variant="outline"
              size="xs"
            >
              <CaretLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => {
                nextPage();
              }}
              disabled={currentPage === totalPages}
              className="ml-2"
              variant="outline"
              size="xs"
            >
              <CaretRightIcon className="h-4 w-4" />
            </Button>
          </span>
        </span>
        <span>
          <DownloadButtons data={proteinSearchResults} />
        </span>
      </header>
      {currentMatchData.map((searchResult) => {
        return (
          <AccordionItem
            key={JSON.stringify(searchResult.match.cdsId)}
            value={searchResult.match.cdsId.cds_shorthand}
            className={cn("rounded-md bg-molstar px-4")}
          >
            <AccordionTrigger className="group -mt-3 flex items-start justify-start !pb-1">
              <div className="flex w-full flex-col items-center">
                <span className="flex w-full items-baseline gap-4 font-semibold">
                  {searchResult.match.clipAnnotation?.clipDescription ??
                    UNANNOTATED_CLIP_LABEL}{" "}
                  <p className="text-xs text-noir-400">
                    {searchResult.score.toFixed(3)}
                  </p>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button size="xs" variant="ghost" className="!px-0">
                        <InfoIcon className="h-3 w-3 text-noir-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p className="!font-normal">
                        Closest Swiss-Prot annotation with cosine similarity
                        score
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </span>
                <div className="w-full">
                  <p
                    className={cn(
                      "text-left text-xs font-light group-data-[state=open]:hidden",
                    )}
                  >
                    {/* should match ActiveCard header in SearchResults.tsx */}
                    {`p__${searchResult.taxonomy.phylum}; s__${searchResult.taxonomy.species}`}
                  </p>
                  <p
                    className={cn(
                      "text-left text-xs font-light group-data-[state=closed]:hidden",
                    )}
                  >
                    {taxonomyToLinageString(searchResult.taxonomy)}
                  </p>
                </div>
                <GenomicContextViz
                  className="mx-auto w-full px-1 py-1 group-data-[state=open]:hidden"
                  maxContextLength={maxContextLength}
                  disablePopover={true}
                  items={[searchResult.match, ...searchResult.contig].map(
                    (record) => ({
                      id: record.cdsId.cds_shorthand,
                      stack: record.cdsId.strand === "forward" ? 0 : 1,
                      start: record.cdsId.start,
                      end: record.cdsId.end,
                      strand: record.cdsId.strand,
                      sequence: record.sequence,
                      clipAnnotation: record.clipAnnotation,
                      isMatch:
                        searchResult.match.cdsId.cds_shorthand ===
                        record.cdsId.cds_shorthand,
                      operonPrediction: record.operonPrediction,
                    }),
                  )}
                />
              </div>
            </AccordionTrigger>
            <AccordionContent className="py-4">
              <ActiveCard
                match={searchResult.match}
                maxContextLength={maxContextLength}
                contig={searchResult.contig}
                alignment={findAlignmentByCDSId(searchResult.match.cdsId)}
                isFetchingAlignment={isFetchingAlignment}
                alignmentError={alignmentError}
                size="full"
              />
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
