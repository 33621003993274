import { useAuth } from "@/api/auth/hooks";
import { SignupRequest, SignupRequestSchema } from "@/api/auth/schemas";
import { Button } from "@/components/ui/button/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

export const SignupPage = () => {
  const { signup } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupRequest>({
    resolver: zodResolver(SignupRequestSchema),
    defaultValues: {
      emailAddress: "",
    },
  });

  const onSubmit = async (data: SignupRequest) => {
    const res = await signup(data);
    if (res.success) {
      alert(res.message);
      navigate("/login");
    } else {
      console.error(res.message);
      alert(res.message);
    }
  };

  return (
    <main className="mx-auto flex min-h-screen flex-col items-center justify-center gap-12 bg-noir-200">
      <header className="flex flex-col items-center gap-6">
        <img src="/tatta_logo.svg" className="-mb-8 h-24" />
        <h2 className="flex gap-1 text-xl font-bold">
          Sign up for <span className="tracking-wide text-brand-600">Gaia</span>
        </h2>
        <p className="mx-auto max-w-md px-2 text-left text-center text-xs text-noir-600">
          Gaia is an embedding-based search engine for sequences. We are
          currently in beta testing, sign up to test new features and help us
          improve Gaia.
        </p>
      </header>

      <form
        className="flex flex-col items-center gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p>
          {`Already have an account?`}{" "}
          <Button variant="link" asChild size="sm" className="px-0">
            <Link to="/login">Log in</Link>
          </Button>
        </p>

        <label className="flex w-80 flex-col gap-2 text-xs">
          <h4 className="font-semibold">Email</h4>
          <Input type="email" {...register("emailAddress")} />
          {errors.emailAddress && (
            <span className="text-red-500">{errors.emailAddress.message}</span>
          )}
        </label>
        <label className="flex w-80 flex-col gap-2 text-xs">
          <h4 className="font-semibold">First Name</h4>
          <Input type="text" {...register("firstName")} />
          {errors.firstName && (
            <span className="text-red-500">{errors.firstName.message}</span>
          )}
        </label>
        <label className="flex w-80 flex-col gap-2 text-xs">
          <h4 className="font-semibold">Last Name</h4>
          <Input type="text" {...register("lastName")} />
          {errors.lastName && (
            <span className="text-red-500">{errors.lastName.message}</span>
          )}
        </label>
        <label className="flex w-80 flex-col gap-2 text-xs">
          <h4 className="font-semibold">Affiliation</h4>
          <Input type="text" {...register("affiliation")} />
          {errors.affiliation && (
            <span className="text-red-500">{errors.affiliation.message}</span>
          )}
        </label>
        <label className="flex items-center justify-center gap-1 text-xs">
          <Checkbox />I have read and agree to the{" "}
          <Button variant="link" className="!px-0">
            <Link to={"https://www.tatta.bio/terms-of-use"}>Terms of Use</Link>
          </Button>{" "}
          and{" "}
          <Button variant="link" className="!px-0">
            <Link to={"https://www.tatta.bio/privacy-policy"}>
              Privacy Policy
            </Link>
          </Button>
          .
        </label>
        <Button type="submit">Sign up</Button>
      </form>
    </main>
  );
};
export default SignupPage;
