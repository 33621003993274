import { useQuery } from "@tanstack/react-query";

import { gaiaAPI } from "@/utils/wretch";
import { AlignmentRequestSchema, AlignmentResponseSchema } from "./schemas";
import { ProteinRecord } from "@/api/protein_search/schemas";
import { useToken } from "@/api/auth/hooks";

export const useAlignment = ({
  query,
  match_list,
}: {
  query: string;
  match_list?: ProteinRecord[];
}) => {
  const token = useToken();

  const queryFn = async () => {
    if (!match_list) {
      return null;
    }
    const payload = AlignmentRequestSchema.parse({
      query,
      match_list: match_list.map((c) => ({
        cdsId: c.cdsId,
        sequence: c.sequence,
      })),
    });

    const res = await gaiaAPI
      .auth(`Bearer ${token}`)
      .url("/alignment")
      .post(payload)
      .json();
    const output = AlignmentResponseSchema.parse(res);
    return output;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["alignment", query, match_list],
    queryFn: queryFn,
  });

  return {
    alignmentData: data,
    isFetchingAlignment: isFetching,
    alignmentError: error as Error | null,
    enabled: !!match_list,
  };
};
