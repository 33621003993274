import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

let environmentName = "development";
if (import.meta.env.VITE_RENDER) {
  if (import.meta.env.VITE_IS_PULL_REQUEST) {
    environmentName = "preview";
  } else {
    environmentName = "production";
  }
}
posthog.init(
  // In development, you can set an arbitrary token in your .env file to opt out of posthog analytics.
  // See https://posthog.com/tutorials/multiple-environments#opt-out-of-capturing-on-initialization
  import.meta.env.VITE_POSTHOG_TOKEN,
  {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always", // creates profiles for anonymous users as well
    autocapture: true,
    loaded: function (ph) {
      if (environmentName !== "production") {
        console.log("Disabling PostHog in non-production environment");
        ph.opt_out_capturing();
        ph.set_config({ disable_session_recording: true });
      }
    },
  },
);
Sentry.init({
  environment: environmentName,
  // if VITE_SENTRY_DSN is not set, Sentry will not be initialized (e.g. in development)
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: environmentName !== "production",
  tunnel: "/sentry_tunnel",
  sendDefaultPii: true,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      maskAllText: false,
      blockAllMedia: false,
    }),
    // The following is all you need to enable canvas recording with Replay
    Sentry.replayCanvasIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      triggerLabel: "Share Feedback",
      formTitle: "Share Feedback",
      submitButtonLabel: "Send Feedback",
      messagePlaceholder:
        "What feedback do you have? If something went wrong, what happened, and what did you expect?",
      successMessageText: "Thanks for your feedback!",
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  // Note that this sample rate is distinct from `traces_sample_rate` on the backend. Though
  // they will often be set to the same value, they are independent and should be set separately.
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function useAnalyticsEvent() {
  function trackCustomEvent({
    eventName,
    params,
  }: {
    eventName: string;
    params?: Record<string, string>;
  }) {
    posthog.capture(eventName, params);
  }

  return { trackCustomEvent };
}
