import { Link } from "react-router-dom";
import { Button } from "./button/button";
import { cn } from "@/utils/strings";

export const Footer = ({ className }: { className?: string }) => {
  const navigation = [
    {
      name: "About",
      href: "https://www.tatta.bio/",
    },
    {
      name: "Terms of Use",
      href: "https://www.tatta.bio/terms-of-use",
    },
    {
      name: "Privacy Policy",
      href: "https://www.tatta.bio/privacy-policy",
    },
    {
      name: "FAQ",
      href: "https://www.tatta.bio/gaia-faqs",
    },
  ];

  return (
    <footer
      className={cn(
        "mx-auto flex w-full max-w-7xl items-center justify-between px-6 py-4",
        className,
      )}
    >
      <span className="flex items-center text-center text-xs md:order-1 md:mt-0">
        <p>&copy; 2024 Tatta Bio, Inc. All rights reserved. </p>
        <Link to="http://www.tatta.bio/gaia-changelog">
          <Button variant="link" className="ml-4 !px-0 font-normal">
            v.{import.meta.env.VITE_GAIA_VERSION}
          </Button>
        </Link>
        <p className="hidden">
          Commit: {import.meta.env.VITE_RENDER_GIT_COMMIT ?? "unknown"}
        </p>
      </span>
      <div className="flex gap-x-6 ">
        {navigation.map((item) => (
          <Button variant="link" key={item.name} className="!px-0 font-normal">
            <Link to={item.href} className="text-xs">
              <span>{item.name}</span>
            </Link>
          </Button>
        ))}
      </div>
    </footer>
  );
};
