import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";

export const gaiaAPI = wretch()
  .addon(QueryStringAddon)
  .url("/api")

  .options({ credentials: "include" })
  .catcher(401, () => {
    alert("Your session has expired, please login again");
    window.location.href = "/login";
  });

export const gaiaAuth = wretch().addon(QueryStringAddon).url("/auth");
