import {
  ProteinRecord,
  ProteinSearchResponseOutput,
} from "@/api/protein_search/schemas";
import { cn } from "@/utils/strings";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { Button } from "./ui/button/button";
import { cdsIdToString } from "@/api/protein_search/utils";

const DownloadButtons = ({
  data,
  className,
}: {
  data?: ProteinSearchResponseOutput;
  className?: string;
}) => {
  const downloadAllSequences = (data: ProteinSearchResponseOutput) => {
    const matches: ProteinRecord[] = data.searchResults.map(
      (match_result) => match_result.match,
    );
    const fastaStr = convertToFasta(data.query.record.sequence, matches);

    const blob = new Blob([fastaStr], { type: "text/text;charset=utf-8" });
    const fileName = `gaia_${getCurrentDateTimeString()}_retrieved_seqs.fa`;

    saveAs(blob, fileName);
  };

  const downloadAllContexts = (data: ProteinSearchResponseOutput) => {
    const files: { blob: Blob; fileName: string }[] = data.searchResults.map(
      (searchResult, searchResultIdx) => {
        const fastaStr = convertToFasta(
          data.query.record.sequence,
          searchResult.contig,
        );
        const blob = new Blob([fastaStr], { type: "text/text;charset=utf-8" });
        const fileName = `gaia_${getCurrentDateTimeString()}_retrieved_seqs_${searchResultIdx}.fa`;
        return { blob, fileName };
      },
    );
    // create zipfile of all the above files
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(file.fileName, file.blob);
    });
    zip.generateAsync({ type: "blob" }).then((content: Blob) => {
      saveAs(
        content,
        `gaia_${getCurrentDateTimeString()}_retrieved_contexts.zip`,
      );
    });
  };

  return (
    <div className={cn("flex gap-1", className)}>
      <Button
        variant="outline"
        className="truncate"
        size="xs"
        disabled={!data}
        onClick={() => {
          if (!data) {
            return;
          }
          downloadAllSequences(data);
        }}
      >
        All retrieved sequences (.fa)
      </Button>
      <Button
        variant="outline"
        className="truncate"
        size="xs"
        disabled={!data}
        onClick={() => {
          if (!data) {
            return;
          }
          downloadAllContexts(data);
        }}
      >
        All retrieved contexts (.zip)
      </Button>
    </div>
  );
};
const convertToFasta = (query: string, records: ProteinRecord[]): string => {
  const res = [];
  const currentCommit =
    import.meta.env.VITE_RENDER_GIT_COMMIT.slice(0, 6) ?? "unknown";
  const formattedDate = new Intl.DateTimeFormat("en-CA", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  })
    .format(new Date())
    .replace(/\//g, "-");
  const versionComment = [
    `; Gaia v.${import.meta.env.VITE_GAIA_VERSION} (${currentCommit}) `,
    `; Date ${formattedDate}`,
    `; DB OG_prot90 (9fdf3e)`,
  ];
  res.push(versionComment.join("\n"));
  const queryBody = `>Query\n${query}`;
  res.push(queryBody);

  const recordsBody = records.map((rec) => {
    const header = `>${cdsIdToString(rec.cdsId)} ${rec.clipAnnotation?.clipDescription ?? "Unannotated"}`;
    const body = rec.sequence;
    return [header, body].join("\n");
  });
  res.push(recordsBody.join("\n"));
  return res.join("\n");
};
function getCurrentDateTimeString(): string {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}_${month}_${day}_${hours}_${minutes}`;
}
export default DownloadButtons;
